<template>
  <main
    class="flex flex-col h-screen bg-gradient-to-br from-red-700 to-red-900 flex-1 relative focus:outline-none overflow-y-hidden"
    tabindex="0"
  >
    <div
      class="w-full italic text-white inline-block place-content-center pt-8 text-base md:text-xl lg:text-3xl font-semibold"
    >
      <p class="font-serif m-4 text-center">
        Welcome to the Knights Templar Provincial Priory of South East Asia's
        Official Website Launch
      </p>
    </div>
    <div class="flex justify-center w-full py-8">
      <button
        class="focus:outline-none w-full"
        @click="
          delay(
            'https://ktppsea.org/login?username=launchsite@example.com&passw=Abcd1234'
          )
        "
      >
        <div class="flex flex-col w-full items-center">
          <div class="flex flex-col w-full items-center py-4">
            <div
              class="flex flex-row items-center content-center w-11/12 justify-evenly"
            >
              <div
                class="flex-auto flex flex-col flex-2 items-center justify-center"
              >
                <div class="flex justify-evenly w-full items-center m-4">
                  <img
                    class="h-20 sm:h-28 md:h-36 lg:h-64"
                    src="../../assets/ppsea_logo.png"
                  />
                </div>
                <div
                  class="mx-1 place-content-center flex flex-col py-4 xl:py-6"
                >
                  <p
                    class="font-uncial text-xl md:text-3xl lg:text-5xl text-center xl:leading-tight pb-2"
                  >
                    Provincial Priory of South East Asia
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </button>
    </div>
  </main>
</template>

<script>
export default {
  name: "Launch",
  components: {},
  methods: {
    start() {
      this.$confetti.start({
        windSpeedMax: 0,
      });
    },
    stop() {
      this.$confetti.stop();
    },
    delay(url) {
      console.log(url);
      this.start();
      setTimeout(() => {
        window.location = url;
        this.stop();
      }, 2000);
    },
  },
};
</script>
